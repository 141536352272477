.labelDiv {
  text-align: center;
}
.avatarLabel {
  vertical-align: middle;
}
.cardTop{
  border: none !important;
}
.cardBodyTop{
  display: flex;
    align-items: baseline;
    justify-content: space-between;
    border: none;
    width: 100%;
}