.mainDiv {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, #a200f6 , #14256a);

}
.adminPanelHeading{
  font-size: 20px !important;
  font-weight: 600 !important;
  margin-top: 90px !important;
  color:#7d28a9 !important;
}
.loginCard{
  display: flex !important;
  flex-direction: inherit !important;
  border-radius: 50px !important;
  box-shadow: rgb(0 4 127) 0px 22px 70px 4px;
  min-height: 460px;
  border: none !important;
  max-width: 850px;
}
.loginImg{
  width: 60% !important;
  margin-top: 95px;
  border-radius: 28px;
  border-top-left-radius: 28px !important;
    border-top-right-radius: 28px !important;
}
.cardBody{
  width: 40%;
  position: relative;
}
.welcomeTag{
  background-image: linear-gradient(to right, #14256a , #a200f6);
  color: #fff;
  padding: 9px;
  margin: 0;
  display: inline-block;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  position: absolute;
  left: 0;
}
.inputFields{
  outline: none !important;
  padding: 0 !important;
  padding-bottom: 7px !important;
  border: none !important;
  border-bottom: 2px solid #7d28a9 !important;
  border-radius: 0 !important;
  margin-top: 30px !important;
}

.inputFields::-webkit-input-placeholder {
  color: #7d28a9;
}

.inputFields::-moz-placeholder {
  color: #7d28a9;
}

.inputFields:-ms-input-placeholder {
  color: #7d28a9;
}

.inputFields::placeholder {
  color: #7d28a9;
}

.loginBtn{
  padding: 8px 24px !important;
  border-radius: 25px !important;
  outline: none !important;
  margin-top: 10px !important;
  background-image: linear-gradient(to right, #14256a , #a200f6) !important;
  border: none !important;
}
.imgDiv{
background-color: #a200f6;
  border-top-left-radius:50px !important;
  border-bottom-left-radius:50px !important;
  width: 60%;
}
.leftDivText{
  padding: 5px 34px;
    margin-top: 20px;
  color: #ffffff;
}
@media screen and (max-width: 580px) {
  .loginCard {
    flex-direction: column !important;
  }
  .imgDiv,.cardBody{
    width: 100% !important;
    border-radius: unset !important;
  }
  .adminPanelHeading{
    margin-top: 40px !important;
  }
}